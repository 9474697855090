<!-- 权限设置 -->
<template lang="">
    <div class="setting">
        <div class="content">
            <div class="roleList">
                <ul>
                    <li :class="{active:selectedRole.id == role.id}" v-for="role in roleList" :key="role.id" @click="selectRole(role)">
                        <div class="name">{{role.name}}</div><i class="el-icon-caret-right"></i>
                    </li>
                </ul>
            </div>

            <div class="permissionList">
                <div class="title flex flex-jc-sb flex-ai-c">
                    <span>功能权限设置</span>
                    <div><el-button type="primary" size="mini" @click="save()" style="width:150px">保存</el-button></div>
                </div>
                <dl>
                    <div v-for="controller in permissionList" :key="controller.id" class="conBox">
                        <dt style="font-size:15px;">
                            <el-checkbox v-model="controller.checked" @change="changController(controller)">{{controller.name}}</el-checkbox>
                        </dt>
                        <dd v-for="action in controller.children" :key="action.id" :class="[!action.children ? 'inBlock' : '']" style="margin:0 15px 0px 0">
                            <div :class="[!action.children ? 'inBlock' : '']" style="padding-left:25px">
                                <el-checkbox  v-model="action.checked" @change="!action.children ? changeAction(controller,action) : changController(action)" >{{action.name}}</el-checkbox>
                            </div>
                            <div style="padding-left:25px">
                                <el-checkbox v-for="tAction in action.children" :key="tAction.id" v-model="tAction.checked" @change="changeAction(action,tAction)">{{tAction.name}}</el-checkbox>
                            </div>
                        </dd>
                    </div>
                </dl>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            permissionList: [],
            all: false,
            roleList: [], //角色列表
            selectedRole: {},
        }
    },
    mounted: function () {
        this.getRoleList(); //角色列表
        this.getDataPermissionList(); //所有权限
    },
    methods: {
        //选择了角色
        selectRole: function (role) {
            this.selectedRole = role;
            this.searchPer();
        },

        //选中角色的接口权限数组
        searchPer: function () {
            this.http.post('/admin.SystemRoleAct/indexChecked', {
                role_id: this.selectedRole.id
            }).then(response => {
                let pathArr = [];
                response.forEach(item => {
                    pathArr.push(item.url)
                });
                let selectRolePer = pathArr;
                let all = this.permissionList;
                for (let controller in all) {
                    if (selectRolePer.indexOf(all[controller].url) > -1) {
                        this.$set(all[controller], 'checked', true);
                    } else {
                        this.$set(all[controller], 'checked', false);
                    }
                    for (let action in all[controller].children) {
                        if (selectRolePer.indexOf(all[controller].children[action].url) > -1) {
                            this.$set(all[controller], 'checked', true);
                            this.$set(all[controller].children[action], 'checked', true);
                        } else {
                            this.$set(all[controller].children[action], 'checked', false);
                        }

                        for (let tAction in all[controller].children[action].children) {
                            if (selectRolePer.indexOf(all[controller].children[action].children[tAction].url) > -1) {
                                this.$set(all[controller], 'checked', true);
                                this.$set(all[controller].children[action], 'checked', true);
                                this.$set(all[controller].children[action].children[tAction], 'checked', true);
                            } else {
                                this.$set(all[controller].children[action], 'checked', false);
                                this.$set(all[controller].children[action].children[tAction], 'checked', false);
                            }
                        }
                    }
                }
                this.permissionList = all;
            })
        },
        //获取角色列表
        getRoleList: function () {
            this.http.post('/admin.SystemRole/index').then(res => {
                this.roleList = res;
                this.selectedRole = this.roleList[0];//默认第一条
                this.searchPer();
            });
        },
        //获取数据权限列表 msn
        getDataPermissionList: function () {
            this.http.post("/admin.SystemRoleAct/indexTree", {}, false).then(res => {
                this.permissionList = res;
            })
        },

        //保存
        save: function () {
            let actionId = [];
            let childId = [];
            this.permissionList.forEach(item => {
                if (item.checked) {
                    actionId.push(item.id)
                    if (item.children) {
                        item.children.forEach(items => {
                            if (items.checked) {
                                childId.push(items.id);
                            }
                        })
                    }
                }
            })

            this.http.post('/admin.SystemRole/save', {
                id: this.selectedRole.id,
                act_id: [...actionId, ...childId],
            }).then(() => {
                this.$message.closeAll()
                this.$message.success('已保存');
            });
        },

        //全选或不全选控制器
        changController: function (controller) {
            if (controller.checked) {
                // this.save(controller); //9.8
                if (controller.children) {
                    for (let action of controller.children) {
                        action.checked = true;
                        if (action.children) {
                            for (let tAction of action.children) {
                                tAction.checked = true;
                                // this.save(tAction);
                            }
                        } else {
                            // this.save(action);
                        }
                    }
                } else {
                    // this.save(controller);
                }
            } else {
                // this.save(controller); //9.8
                if (controller.children) {
                    for (let action of controller.children) {
                        action.checked = false;
                        if (action.children) {
                            for (let tAction of action.children) {
                                tAction.checked = false;
                                // this.save(tAction);
                            }
                        } else {
                            // this.save(action);
                        }
                    }
                } else {
                    // this.save(controller);
                }
            }
        },

        changeAction: function (controller, action) {
            controller.checked = false;
            let trueArr = [];
            for (var a of controller.children) {
                if (a.checked) {
                    controller.checked = true;
                    trueArr.push(controller.checked)
                }
            }
            if (controller.children.length == trueArr.length || trueArr.length == 0 || trueArr.length == 1) {
                // this.save(controller)
            }
            // this.save(action);
        }
    }
}
</script>
<style lang="less" scoped >
.inBlock {
    display: inline-block;
}
.setting {
    color: #333;
    position: relative;
    height: 100%;
    padding: 0 20px 10px;
    box-sizing: border-box;
}
.link {
    cursor: pointer;
    color: #008b50;
}
.selectionBar {
    overflow: hidden;
    .group {
        float: left;
        overflow: hidden;
        .name {
            float: left;
            font-size: 14px;
            line-height: 40px;
        }
    }
}

.content {
    margin: 0 20px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    top: 20px;
    .roleList {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        width: 200px;
        background: #fff;
        box-shadow: 0 0 5px rgba(128, 152, 213, 0.31);
        ul {
            overflow: auto;
            height: 100%;
            li {
                font-size: 14px;
                line-height: 50px;
                display: flex;
                padding: 0 15px;
                cursor: pointer;
                color: #555;
                &.active {
                    background: #eaf0ff;
                    color: #409eff;
                }
                &:hover {
                    background: #eaf0ff;
                }
                .name {
                    flex-grow: 1;
                }
                i {
                    flex-shrink: 0;
                    line-height: 50px;
                    font-size: 20px;
                }
            }
        }
    }
    .permissionList {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        left: 210px;
        background: #fff;
        box-shadow: 0 0 5px rgba(128, 152, 213, 0.31);
        .title {
            line-height: 50px;
            border-bottom: 1px #e0e0e0 solid;
            padding: 0 15px;
            color: #555;
            font-size: 15px;
        }
        dl {
            overflow: auto;
            position: absolute;
            right: 0;
            bottom: 0;
            top: 51px;
            left: 0;
            padding: 15px;
            .all {
                margin-bottom: 15px;
            }
            .conBox {
                line-height: 2;
                dt {
                    margin: 10px 0;
                }
            }
            .conBox:first-child {
                dt {
                    margin-top: 0;
                }
            }
        }
    }
    .dataPermission {
        position: absolute;
        right: 0px;
        bottom: 0;
        top: 0;
        width: 200px;
        background: #fff;
        box-shadow: 0 0 5px rgba(128, 152, 213, 0.31);
        .title {
            line-height: 50px;
            border-bottom: 1px #e0e0e0 solid;
            padding: 0 15px;
            color: #555;
        }
        dl {
            overflow: auto;
            position: absolute;
            right: 0;
            bottom: 0;
            top: 51px;
            left: 0;
            padding: 15px;
        }
    }
}
</style>
